import axios from "axios";

const base_url =
    process.env.NEXT_PUBLIC_DEVELOPMENT === "development"
        ? "https://cloudrun-x2lc2wibla-ew.a.run.app/"
        : "https://cloudrun-pgjjiy2k6a-ew.a.run.app/";

async function getCollectionData(collectionName: string, user?: any): Promise<any> {
    const URL = base_url + "get_all_firestore_document";
    let headers: any = {
        Accept: "application/json",
    };

    if (user) {
        const token = user;
        headers["Authorization"] = "Bearer " + token;
    }
    const data = {
        collectionName: collectionName,
    };

    const promise = axios({
        headers: headers,
        url: URL,
        method: "post",
        timeout: 5000,
        data: data,
    });
    return await promise.then((response: any) => response).catch(() => ({ status: 444 }));
}

async function getDocument(collectionName: string, documentId: string, user?: any): Promise<any> {
    const URL = base_url + "get_firestore_document";
    let headers: any = {
        Accept: "application/json",
    };

    if (user) {
        const token = user;
        headers["Authorization"] = "Bearer " + token;
    }
    const data = {
        collectionName: collectionName,
        documentId: documentId,
    };

    const promise = axios({
        headers: headers,
        url: URL,
        method: "post",
        timeout: 5000,
        data: data,
    });
    return await promise.then((response: any) => response).catch(() => ({ status: 444 }));
}

async function deleteDocument(
    collectionName: string,
    documentId: string,
    user?: any
): Promise<any> {
    const URL = base_url + "delete_document";
    let headers: any = {
        Accept: "application/json",
    };

    if (user) {
        const token = user;
        headers["Authorization"] = "Bearer " + token;
    }
    const data = {
        collectionName: collectionName,
        documentId: documentId,
    };

    const promise = axios({
        headers: headers,
        url: URL,
        method: "post",
        timeout: 5000,
        data: data,
    });
    return await promise.then((response: any) => response).catch(() => ({ status: 444 }));
}

async function getDocumentWithQuery(
    collectionName: string,
    query: string,
    query_filed: string,
    search_query: string,
    user?: any
): Promise<any> {
    const URL = base_url + "get_firestore_document_with_query";
    let headers: any = {
        Accept: "application/json",
    };

    if (user) {
        const token = user;
        headers["Authorization"] = "Bearer " + token;
    }
    const data = {
        collectionName: collectionName,
        query: query,
        query_filed: query_filed,
        search_query: search_query,
    };

    const promise = axios({
        headers: headers,
        url: URL,
        method: "post",
        timeout: 5000,
        data: data,
    });
    return await promise.then((response: any) => response).catch(() => ({ status: 444 }));
}

async function getAllDocumentWithQuery(
    collectionName: string,
    query: string,
    query_filed: string,
    search_query: string,
    user?: any
): Promise<any> {
    const URL = base_url + "get_all_firestore_documents_with_query";
    let headers: any = {
        Accept: "application/json",
    };

    if (user) {
        const token = user;
        headers["Authorization"] = "Bearer " + token;
    }
    const data = {
        collectionName: collectionName,
        query: query,
        query_filed: query_filed,
        search_query: search_query,
    };

    const promise = axios({
        headers: headers,
        url: URL,
        method: "post",
        timeout: 5000,
        data: data,
    });
    return await promise.then((response: any) => response).catch(() => ({ status: 444 }));
}

async function getDynamicQuery(
    collectionName: string,
    filters?: string[],
    order_by?: string,
    order_direction?: string,
    limit?:number,
    offset?:any,
    user?: any
): Promise<any> {
    const URL = base_url + "dynamic_query_document";
    let headers: any = {
        Accept: "application/json",
    };

    if (user) {
        const token = user;
        headers["Authorization"] = "Bearer " + token;
    }
    let data:any = {
        collectionName: collectionName
    };
    if (filters){
        data["filters"] = filters;
    }
    if (order_by){
        data["order_by"] = order_by;
    }
    if (order_direction){
        data["order_direction"] = order_direction;
    }
    if (limit){
        data["limit"] = limit;
    }
    if (offset){
        data["offset"] = offset;
    }

    const promise = axios({
        headers: headers,
        url: URL,
        method: "post",
        timeout: 5000,
        data: data,
    });
    return await promise.then((response: any) => response).catch(() => ({ status: 444 }));
}

async function updateDocument(
    collectionName: string,
    documentId: string,
    newData: any,
    user?: any
): Promise<any> {
    const URL = base_url + "update_document";
    let headers: any = {
        Accept: "application/json",
    };

    if (user) {
        const token = user;
        headers["Authorization"] = "Bearer " + token;
    }
    const data = {
        collectionName: collectionName,
        documentId: documentId,
        data: newData,
    };

    const promise = axios({
        headers: headers,
        url: URL,
        method: "post",
        timeout: 5000,
        data: data,
    });
    return await promise.then((response: any) => response).catch(() => ({ status: 444 }));
}

async function addDocument(collectionName: string, newData: any, user?: any): Promise<any> {
    const URL = base_url + "add_document";
    let headers: any = {
        Accept: "application/json",
    };

    if (user) {
        const token = user;
        headers["Authorization"] = "Bearer " + token;
    }
    const data = {
        collectionName: collectionName,
        data: newData,
    };

    const promise = axios({
        headers: headers,
        url: URL,
        method: "post",
        timeout: 5000,
        data: data,
    });
    return await promise.then((response: any) => response).catch(() => ({ status: 444 }));
}

async function addReference(collectionName: string, user?: any): Promise<any> {
    const URL = base_url + "add_doc_reference";
    let headers: any = {
        Accept: "application/json",
    };

    if (user) {
        const token = user;
        headers["Authorization"] = "Bearer " + token;
    }
    const data = {
        collectionName: collectionName,
    };

    const promise = axios({
        headers: headers,
        url: URL,
        method: "post",
        timeout: 5000,
        data: data,
    });
    return await promise.then((response: any) => response).catch(() => ({ status: 444 }));
}

async function have_participated(owner:string, run_guid:string): Promise<any> {
    const URL = base_url + "have_participated";
    let headers: any = {
        Accept: "application/json",
    };
    
    const data = {
        owner: owner,
        run_guid:run_guid
    };

    const promise = axios({
        headers: headers,
        url: URL,
        method: "post",
        timeout: 5000,
        data: data,
    });
    return await promise.then((response: any) => response).catch(() => ({ status: 444 }));
}

export {
    getCollectionData,
    updateDocument,
    addDocument,
    getDocument,
    getDocumentWithQuery,
    getAllDocumentWithQuery,
    addReference,
    deleteDocument,
    getDynamicQuery,
    have_participated
};
