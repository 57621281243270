import React, { createContext, FC, useContext, useState } from 'react';

// Create a Context for the shared state
const SharedStateContext = createContext<any>("metric");

interface ShareStateProps{
    children:any;
}
// Create a provider component
const SharedStateProvider:FC<ShareStateProps> = ({ children }) => {
  const [sharedValue, setSharedValue] = useState<any>("metric");

  return (
    <SharedStateContext.Provider value={{ sharedValue, setSharedValue }}>
      {children}
    </SharedStateContext.Provider>
  );
}

// Custom hook to use the shared state
export function useSharedState() {
  const context = useContext(SharedStateContext);
  if (!context) {
    throw new Error("useSharedState must be used within a SharedStateProvider");
  }
  return context;
}

export {SharedStateProvider};
