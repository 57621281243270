// import i18n from "i18next";
// import { initReactI18next } from "next-i18next";
// import LanguageDetector from "i18next-browser-languagedetector";
// import HttpBackend from "i18next-http-backend";
// // import customPath from "./runagainDetector";
// const fallbackLng = ["en"];
// const availableLanguages = ["no", "en", "sv", "da"];

// const options = {
//   order: ['customPath', 'localStorage', 'querystring', 'navigator'],
//   // reset of options
// };
// const languageDetector = new LanguageDetector(null, options);

// languageDetector.addDetector(customPath);

// eslint-disable-next-line import/no-named-as-default-member
// i18n
//   .use(languageDetector) // detect user language
//   .use(initReactI18next) // pass the i18n instance to next-i18next.
//   .use(HttpBackend)
//   .init({
//     fallbackLng, // fallback language is english.
//     supportedLngs: availableLanguages,
//     load: "currentOnly",
//     keySeparator: "."
//   });

// export default i18n;

module.exports = {
  // i18n: {
  //   locales: ['en', 'da', 'no', 'sv'],
  //   defaultLocale: 'en',
  //   // pages: {
  //   //   '*': ['translation'],
  //   // },
  // },
  // i18n: {
  // loadLocaleFrom: (lang, ns) =>
  //     // You can use a dynamic import, fetch, whatever. You should
  //     // return a Promise with the JSON file.
  //     import(`./locales/${lang}/${ns}.json`).then((m) => m.default),
  locales: ["en", "es", "da", "de", "fr", "no", "sv"],
  defaultLocale: "en",
  //this line,
  pages: {
    '*': ['translation', 'ads', 'profilePage', 'masterclassPage'],
    "/about-us": ['about-us'],
    "/coaches": ['coachPortal'],
    "/edit-run": ['runPage', 'editRun', 'organizerPortal'],
    "/edit-run/[run]": ['runPage', 'editRun', 'organizerPortal'],
    "/grow-your-event": ['partnerPage'],
    "/invest": ['invest'],
    "/premium": ['masterclassPage'],
    "/premium/[offer]": ['masterclassPage'],
    "/premium/order": ['masterclassPage'],
    "/premium/order/[orderid]": ['masterclassPage'],
    "/organizer-portal": ['organizerPortal', 'partnerPage'],
    "/profile": ['profilePage', 'runPage'],
    "/profile/[username]": ['profilePage', 'runPage'],
    "/ra-contest": ['contest'],
    "/race-admin-portal": ['organizerPortal'],
    "/run/[run]": ['runPage'],
    "/run/[run]/[date]": ['runPage'],
    "/running-program": ['runningPlan'],
    "/signup": ['signup']
  },
  // reloadOnPrerender: true,
  localeDetection: false
  // }
};
