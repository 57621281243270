const developmentFirebaseConfig = {
  apiKey: "AIzaSyCXHRDtyGzTDFD7ww9FX0kCz5nuBK2MCfs",
  authDomain: "nestelop-test.firebaseapp.com",
  databaseURL: "https://nestelop-test.firebaseio.com",
  projectId: "nestelop-test",
  storageBucket: "nestelop-test.appspot.com",
  messagingSenderId: "187821535590",
  appId: "1:187821535590:web:2ff283fb83e1cd007d18a7",
  measurementId: "G-4Z1WV3KZFN",
};

const pilotFirebaseConfig = {
  apiKey: "AIzaSyAdWgPm5rMwqWqIOexAf8cm3ZEg9gfbnT8",
  authDomain: "nestelop-pilot.firebaseapp.com",
  databaseURL: "https://nestelop-pilot.firebaseio.com",
  projectId: "nestelop-pilot",
  storageBucket: "nestelop-pilot.appspot.com",
  messagingSenderId: "380517848339",
  appId: "1:380517848339:web:65af09de703a6fcc4176e6",
  measurementId: "G-LKZBSR7M3M",
};

const productionFirebaseConfig = {
  apiKey: "AIzaSyBrjfB1WOdrm-z5iDVmiaC8BJoyolv8q1U",
  authDomain: "nestelop-production.firebaseapp.com",
  databaseURL: "https://nestelop-production.firebaseio.com",
  projectId: "nestelop-production",
  storageBucket: "nestelop-production.appspot.com",
  messagingSenderId: "468057685962",
  appId: "1:468057685962:web:3be2a8851fad5aaab89933",
  measurementId: "G-3ET4QXJ5XE",
};

const firebaseConfig =
  process.env.NEXT_PUBLIC_DEVELOPMENT === "development"
    ? developmentFirebaseConfig
    : process.env.NEXT_PUBLIC_DEVELOPMENT === "pilot"
      ? pilotFirebaseConfig
      : productionFirebaseConfig;

export const firebaseClientEmail =
  process.env.NEXT_PUBLIC_DEVELOPMENT === "development"
    ? "firebase-adminsdk-bcglj@nestelop-test.iam.gserviceaccount.com"
      : "firebase-adminsdk-27egi@nestelop-production.iam.gserviceaccount.com";

export default firebaseConfig;
