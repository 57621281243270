import axios from "axios";

async function getLatestBlogs(): Promise<any> {
    const URL =
        process.env.NEXT_PUBLIC_DEVELOPMENT === "development"
            ? "https://cloudrun-x2lc2wibla-ew.a.run.app/get_latest_blogs"
            : "https://cloudrun-pgjjiy2k6a-ew.a.run.app/get_latest_blogs";
    const headers = {
        Accept: "application/json",
    };
 
    const promise = axios({
        headers: headers,
        url: URL,
        method: "get",
        timeout: 5000
    });
    return await promise.then((response: any) => response).catch(() => ({ status: 444 }));
}

async function getBlog(limit:number=9, offset:number=0): Promise<any> {
    const URL =
        process.env.NEXT_PUBLIC_DEVELOPMENT === "development"
            ? "https://cloudrun-x2lc2wibla-ew.a.run.app/get_blogs"
            : "https://cloudrun-pgjjiy2k6a-ew.a.run.app/get_blogs";
    const headers = {
        Accept: "application/json",
    };
    const data = {
        limit:limit,
        offset:offset
    }
 
    const promise = axios({
        headers: headers,
        url: URL,
        method: "post",
        data:data,
        timeout: 5000
    });
    return await promise.then((response: any) => response).catch(() => ({ status: 444 }));
}

export {getLatestBlogs, getBlog}