// @ts-nocheck
import {init} from '@/helpers/authHandler';
import firebaseConfig, { firebaseClientEmail } from "./firebaseConfig";


const initAuth = () => {
  init({
    debug: false,
    authPageURL: "/login",

    // This demonstrates setting a dynamic destination URL when
    // redirecting from auth pages. Alternatively, you can simply
    // specify `appPageURL: '/'`.
    appPageURL: "/",
    loginAPIEndpoint: '/api/login',
    logoutAPIEndpoint: '/api/logout',
    onLoginRequestError: (err:any) => {
      console.error(err)
    },
    onLogoutRequestError: (err:any) => {
        console.error(err)
    },
    firebaseAdminInitConfig: {

      credential: {
        projectId: firebaseConfig.projectId,
        clientEmail: firebaseClientEmail,
        // The private key must not be accessible on the client side.
        privateKey: process.env.FIREBASE_PRIVATE_KEY ? Buffer.from(process.env.FIREBASE_PRIVATE_KEY, 'base64').toString('ascii').replace(/\\n/gm, "\n"): "",
      },
      databaseURL: firebaseConfig.databaseURL,
    },
    // Use application default credentials (takes precedence over firebaseAdminInitConfig if set)
    // useFirebaseAdminDefaultCredential: true,
    firebaseClientInitConfig: firebaseConfig,
    cookies: {
      name: process.env.NEXT_PUBLIC_COOKIE_NAME, // required
      // Keys are required unless you set `signed` to `false`.
      // The keys cannot be accessible on the client side.
      keys: [
        process.env.COOKIE_SECRET_CURRENT,
        process.env.COOKIE_SECRET_PREVIOUS,
      ],
      httpOnly: true,
      maxAge: 12 * 60 * 60 * 24 * 1000, // twelve days
      overwrite: true,
      path: '/',
      sameSite: 'lax',
      secure: false, // set this to false in local (non-HTTPS) development
      signed: true, // TODO: switch this back
    },
    onVerifyTokenError: (err:any) => {
      console.error(err)
    },
    onTokenRefreshError: (err:any) => {
      console.error(err)
    },
    // tokenChangedHandler: (err) =>{
    //   console.error(err)
    // }
  });
}

export default initAuth