const testDonationIntentUrl =
  "https://europe-west1-nestelop-test.cloudfunctions.net/RequestIntent";
const pilotDonationIntentUrl =
  "https://europe-west1-nestelop-pilot.cloudfunctions.net/RequestIntent";
const prodDonationIntentUrl =
  "https://europe-west1-nestelop-production.cloudfunctions.net/RequestIntent";

const testUpdateDonationUrl =
  "https://europe-west1-nestelop-test.cloudfunctions.net/UpdateDonation";
const pilotUpdateDonationUrl =
  "https://europe-west1-nestelop-pilot.cloudfunctions.net/UpdateDonation";
const prodUpdateDonationUrl =
  "https://europe-west1-nestelop-production.cloudfunctions.net/UpdateDonation";

const testCreateOrder =
  "https://europe-west1-nestelop-test.cloudfunctions.net/CreateOrder";
const pilotCreateOrder =
  "https://europe-west1-nestelop-pilot.cloudfunctions.net/CreateOrder";
const prodCreateOrder =
  "https://europe-west1-nestelop-production.cloudfunctions.net/CreateOrder";

const testRequestCoachIntent =
  "https://europe-west1-nestelop-test.cloudfunctions.net/RequestCoachIntent";
const pilotRequestCoachIntent =
  "https://europe-west1-nestelop-pilot.cloudfunctions.net/RequestCoachIntent";
const prodRequestCoachIntent =
  "https://europe-west1-nestelop-production.cloudfunctions.net/RequestCoachIntent";

const testcreateComment =
  "https://europe-west1-nestelop-test.cloudfunctions.net/PostComments";
const pilotcreateComment =
  "https://europe-west1-nestelop-test.cloudfunctions.net/PostComments";
const prodcreateComment =
  "https://europe-west1-nestelop-production.cloudfunctions.net/PostComments";

const testGetComments =
  "https://europe-west1-nestelop-test.cloudfunctions.net/GetComments";
const pilotGetComments =
  "https://europe-west1-nestelop-test.cloudfunctions.net/GetComments";
const prodGetComments =
  "https://europe-west1-nestelop-production.cloudfunctions.net/GetComments";

const testGetRating =
  "https://europe-west1-nestelop-test.cloudfunctions.net/GetRating";
const pilotGetRating =
  "https://europe-west1-nestelop-test.cloudfunctions.net/GetRating";
const prodGetRating =
  "https://europe-west1-nestelop-production.cloudfunctions.net/GetRating";

const testLikeComment =
  "https://europe-west1-nestelop-test.cloudfunctions.net/UpdateLikesOnComments";
const pilotLikeComment = "";
const prodLikeComment =
  "https://europe-west1-nestelop-production.cloudfunctions.net/UpdateLikesOnComments";


const testCreateApplication =
  "https://europe-west1-nestelop-test.cloudfunctions.net/CreateApplication";
const pilotCreateApplication = "";
const prodCreateApplication =
  "https://europe-west1-nestelop-production.cloudfunctions.net/CreateApplication";

const testCreateParticipant =
  "https://europe-west1-nestelop-test.cloudfunctions.net/CreateParticipant";
const pilotCreateParticipant = "";
const prodCreateParticipant =
  "https://europe-west1-nestelop-production.cloudfunctions.net/CreateParticipant";

const testRemoveParticipant =
  "https://europe-west1-nestelop-test.cloudfunctions.net/RemoveParticipant";
const pilotRemoveParticipant = "";
const prodRemoveParticipant =
  "https://europe-west1-nestelop-production.cloudfunctions.net/RemoveParticipant";

const testAddRaceToParticipant =
  "https://europe-west1-nestelop-test.cloudfunctions.net/AddRaceToParticipant";
const pilotAddRaceToParticipant = "";
const prodAddRaceToParticipant =
  "https://europe-west1-nestelop-production.cloudfunctions.net/AddRaceToParticipant";

const testAddParticipantToRace =
  "https://europe-west1-nestelop-test.cloudfunctions.net/AddParticipantToRace";
const pilotAddParticipantToRace = "";
const prodAddParticipantToRace =
  "https://europe-west1-nestelop-production.cloudfunctions.net/AddParticipantToRace";

const testRemoveParticipantFromRace =
  "https://europe-west1-nestelop-test.cloudfunctions.net/RemoveParticipantFromRace";
const pilotRemoveParticipantFromRace = "";
const prodRemoveParticipantFromRace =
  "https://europe-west1-nestelop-production.cloudfunctions.net/RemoveParticipantFromRace";

const testGetAllParticipants =
  "https://europe-west1-nestelop-test.cloudfunctions.net/GetAllParticipants";
const pilotGetAllParticipants = "";
const prodGetAllParticipants =
  "https://europe-west1-nestelop-production.cloudfunctions.net/GetAllParticipants";

const testGetParticipantRaceRegistrations =
  "https://europe-west1-nestelop-test.cloudfunctions.net/GetParticipantRaceRegistrations";
const pilotGetParticipantRaceRegistrations = "";
const prodGetParticipantRaceRegistrations =
  "https://europe-west1-nestelop-production.cloudfunctions.net/GetParticipantRaceRegistrations";

const testGetRegistrationData =
  "https://europe-west1-nestelop-test.cloudfunctions.net/GetRegistrationData";
const pilotGetRegistrationData = "";
const prodGetRegistrationData =
  "https://europe-west1-nestelop-production.cloudfunctions.net/GetRegistrationData";

const testNewPartnerRequest =
  "https://europe-west1-nestelop-test.cloudfunctions.net/NewPartnerRequest";
const pilotNewPartnerRequest = "";
const prodNewPartnerRequest =
  "https://europe-west1-nestelop-production.cloudfunctions.net/NewPartnerRequest";

//Users
const testGetAllUsers =
  "https://europe-west1-nestelop-test.cloudfunctions.net/search_users";
const pilotGetAllUsers = "";
const prodGetAllUsers =
  "https://europe-west1-nestelop-production.cloudfunctions.net/search_users";

const testSendFriendRequest =
  "https://cloudrun-x2lc2wibla-ew.a.run.app/send_friend_request";
const pilotSendFriendRequest = "";
const prodSendFriendRequest =
  "https://cloudrun-pgjjiy2k6a-ew.a.run.app/send_friend_request";

const testHandleFriendRequest =
  "https://cloudrun-x2lc2wibla-ew.a.run.app/handle_friend_request";
const pilotHandleFriendRequest = "";
const prodHandleFriendRequest =
  "https://cloudrun-pgjjiy2k6a-ew.a.run.app/handle_friend_request";

const testGetUserInformation =
  "https://europe-west1-nestelop-test.cloudfunctions.net/getUserInformation";
const pilotGetUserInformation = "";
const prodGetUserInformation =
  "https://europe-west1-nestelop-production.cloudfunctions.net/getUserInformation";

const testGetBasicUserInformation =
  "https://europe-west1-nestelop-test.cloudfunctions.net/getBasicUserInformation";
const pilotGetBasicUserInformation = "";
const prodGetBasicUserInformation =
  "https://europe-west1-nestelop-production.cloudfunctions.net/getBasicUserInformation";

const testGetAllCoaches =
  "https://europe-west1-nestelop-test.cloudfunctions.net/getAllCoaches";
const pilotGetAllCoaches = "";
const prodGetAllCoaches =
  "https://europe-west1-nestelop-production.cloudfunctions.net/getAllCoaches";

const testGetCoachData =
  "https://europe-west1-nestelop-test.cloudfunctions.net/getCoacheData";
const pilotGetCoachData = "";
const prodGetCoachData =
  "https://europe-west1-nestelop-production.cloudfunctions.net/getCoacheData";

const testNewOrderedOffer =
  "https://europe-west1-nestelop-test.cloudfunctions.net/NewOrderdOffer";
const pilotNewOrderedOffer = "";
const prodNewOrderedOffer =
  "https://europe-west1-nestelop-production.cloudfunctions.net/NewOrderdOffer";

const testSendConfirmRegistration =
  "https://europe-west1-nestelop-test.cloudfunctions.net/SendConfirmRegistration";
const pilotSendConfirmRegistration = "";
const prodSendConfirmRegistration =
  "https://europe-west1-nestelop-production.cloudfunctions.net/SendConfirmRegistration";

//Organizers
const testGetOrganizerData =
  "https://cloudrun-x2lc2wibla-ew.a.run.app/get_organizer_data";
const pilotGetOrganizerData = "";
const prodGetOrganizerData =
  "https://cloudrun-pgjjiy2k6a-ew.a.run.app/get_organizer_data";

const testGetOrganizerContactInfo =
  "https://cloudrun-x2lc2wibla-ew.a.run.app/get_organizer_contact_info";
const pilotGetOrganizerContactInfo = "";
const prodGetOrganizerContactInfo =
  "https://cloudrun-pgjjiy2k6a-ew.a.run.app/get_organizer_contact_info";

const testAddRaceReference =
  "https://europe-west1-nestelop-test.cloudfunctions.net/add_race_reference";
const pilotAddRaceReference = "";
const prodAddRaceReference =
  "https://europe-west1-nestelop-production.cloudfunctions.net/add_race_reference";

const testAddOwnedRun =
  "https://europe-west1-nestelop-test.cloudfunctions.net/add_new_race";
const pilotAddOwnedRun = "";
const prodAddOwnedRun =
  "https://europe-west1-nestelop-production.cloudfunctions.net/add_new_race";

const testAddRaceToOrganizer =
  "https://europe-west1-nestelop-test.cloudfunctions.net/add_race_to_organizer";
const pilotAddRaceToOrganizer = "";
const prodAddRaceToOrganizer =
  "https://europe-west1-nestelop-production.cloudfunctions.net/add_race_to_organizer";

const testUpdateOwnedRun =
  "https://cloudrun-x2lc2wibla-ew.a.run.app/update_race";
const pilotUpdateOwnedRun = "";
const prodUpdateOwnedRun =
  "https://cloudrun-pgjjiy2k6a-ew.a.run.app/update_race";

const testGetLatestComments =
  "https://europe-west1-nestelop-test.cloudfunctions.net/get_latest_comments";
const pilotGetLatestComments = "";
const prodGetLatestComments =
  "https://europe-west1-nestelop-production.cloudfunctions.net/get_latest_comments";

const testGetListOfUsers =
  "https://cloudrun-x2lc2wibla-ew.a.run.app/get_user_list";
const pilotGetListOfUsers = "";
const prodGetListOfUsers =
  "https://cloudrun-pgjjiy2k6a-ew.a.run.app/get_user_list";

const testAddNewParticipant =
  "https://europe-west1-nestelop-test.cloudfunctions.net/add_new_participant";
const pilotAddNewParticipant = "";
const prodAddNewParticipant =
  "https://europe-west1-nestelop-production.cloudfunctions.net/add_new_participant";

const testRemoveParticipantCF =
  "https://europe-west1-nestelop-test.cloudfunctions.net/remove_participant";
const pilotRemoveParticipantCF = "";
const prodRemoveParticipantCF =
  "https://europe-west1-nestelop-production.cloudfunctions.net/remove_participant";

const testMostViewed =
  "https://cloudrun-x2lc2wibla-ew.a.run.app/most_viewed";
const pilotMostViewed = "https://cloudrun-x2lc2wibla-ew.a.run.app/most_viewed";
const prodMostViewed =
  "https://cloudrun-pgjjiy2k6a-ew.a.run.app/most_viewed";

const testActivateOrganizerRole =
  "https://cloudrun-x2lc2wibla-ew.a.run.app/activate_organizer_role";
const pilotActivateOrganizerRole = "https://cloudrun-x2lc2wibla-ew.a.run.app/add_event";
const prodActivateOrganizerRole =
  "https://cloudrun-pgjjiy2k6a-ew.a.run.app/activate_organizer_role";


const testSearchUsers =
  "https://cloudrun-x2lc2wibla-ew.a.run.app/user_search";
const pilotSearchUsers = "https://cloudrun-x2lc2wibla-ew.a.run.app/user_search";
const prodSearchUsers =
  "https://cloudrun-pgjjiy2k6a-ew.a.run.app/user_search";

const testSuggestedFriends =
  "https://cloudrun-x2lc2wibla-ew.a.run.app/suggested_friends";
const pilotSuggestedFriends = "https://cloudrun-x2lc2wibla-ew.a.run.app/suggested_friends";
const prodSuggestedFriends =
  "https://cloudrun-pgjjiy2k6a-ew.a.run.app/suggested_friends";

const testGetAllRuns =
  "https://cloudrun-x2lc2wibla-ew.a.run.app/get_runs";
const pilotGetAllRuns = "https://cloudrun-x2lc2wibla-ew.a.run.app/get_runs";
const prodGetAllRuns =
  "https://cloudrun-pgjjiy2k6a-ew.a.run.app/get_runs";

const testGetCountRuns =
  "https://cloudrun-x2lc2wibla-ew.a.run.app/get_count_runs";
const pilotGetCountRuns = "https://cloudrun-x2lc2wibla-ew.a.run.app/get_count_runs";
const prodGetCountRuns =
  "https://cloudrun-pgjjiy2k6a-ew.a.run.app/get_count_runs";

const testFindRuns =
  "https://cloudrun-x2lc2wibla-ew.a.run.app/find_runs";
const pilotFindRuns = "https://cloudrun-x2lc2wibla-ew.a.run.app/find_runs";
const prodFindRuns =
  "https://cloudrun-pgjjiy2k6a-ew.a.run.app/find_runs";

const testUrlChecker =
  "https://cloudrun-x2lc2wibla-ew.a.run.app/url_checker";
const pilotUrlChecker = "https://cloudrun-x2lc2wibla-ew.a.run.app/url_checker";
const prodUrlChecker =
  "https://cloudrun-pgjjiy2k6a-ew.a.run.app/url_checker";

const testGetPartners =
  "https://cloudrun-x2lc2wibla-ew.a.run.app/get_partners";
const pilotGetPartners = "https://cloudrun-x2lc2wibla-ew.a.run.app/get_partners";
const prodGetPartners =
  "https://cloudrun-pgjjiy2k6a-ew.a.run.app/get_partners";

const testGetCountries =
  "https://cloudrun-x2lc2wibla-ew.a.run.app/get_all_countries";
const pilotGetCountries = "https://cloudrun-x2lc2wibla-ew.a.run.app/get_all_countries";
const prodGetCountries =
  "https://cloudrun-pgjjiy2k6a-ew.a.run.app/get_all_countries";

const testGetSubmenuData =
  "https://cloudrun-x2lc2wibla-ew.a.run.app/get_submenu_data";
const pilotGetSubmenuData = "https://cloudrun-x2lc2wibla-ew.a.run.app/get_submenu_data";
const prodGetSubmenuData =
  "https://cloudrun-pgjjiy2k6a-ew.a.run.app/get_submenu_data";

const testGetRunTitles =
  "https://cloudrun-x2lc2wibla-ew.a.run.app/get_all_titles";
const pilotGetRunTitles = "https://cloudrun-x2lc2wibla-ew.a.run.app/get_all_titles";
const prodGetRunTitles =
  "https://cloudrun-pgjjiy2k6a-ew.a.run.app/get_all_titles";

const testGetMapdata =
  "https://cloudrun-x2lc2wibla-ew.a.run.app/get_map_data";
const pilotGetMapdata = "https://cloudrun-x2lc2wibla-ew.a.run.app/get_map_data";
const prodGetMapdata =
  "https://cloudrun-pgjjiy2k6a-ew.a.run.app/get_map_data";

const testGetProgram =
  "https://cloudrun-x2lc2wibla-ew.a.run.app/get_running_programs";
const pilotGetProgram = "https://cloudrun-x2lc2wibla-ew.a.run.app/get_running_programs";
const prodGetProgram =
  "https://cloudrun-pgjjiy2k6a-ew.a.run.app/get_running_programs";

const testUpdateLikes =
  "https://cloudrun-x2lc2wibla-ew.a.run.app/update_likes_on_comment";
const pilotUpdateLikes = "https://cloudrun-x2lc2wibla-ew.a.run.app/update_likes_on_comment";
const prodUpdateLikes =
  "https://cloudrun-pgjjiy2k6a-ew.a.run.app/update_likes_on_comment";

const donationIntentUrl =
  process.env.NEXT_PUBLIC_DEVELOPMENT === "development"
    ? testDonationIntentUrl
    : process.env.NEXT_PUBLIC_DEVELOPMENT === "pilot"
      ? pilotDonationIntentUrl
      : prodDonationIntentUrl;

const updateDonationUrl =
  process.env.NEXT_PUBLIC_DEVELOPMENT === "development"
    ? testUpdateDonationUrl
    : process.env.NEXT_PUBLIC_DEVELOPMENT === "pilot"
      ? pilotUpdateDonationUrl
      : prodUpdateDonationUrl;

const createOrderUrl =
  process.env.NEXT_PUBLIC_DEVELOPMENT === "development"
    ? testCreateOrder
    : process.env.NEXT_PUBLIC_DEVELOPMENT === "pilot"
      ? pilotCreateOrder
      : prodCreateOrder;

const coachIntentUrl =
  process.env.NEXT_PUBLIC_DEVELOPMENT === "development"
    ? testRequestCoachIntent
    : process.env.NEXT_PUBLIC_DEVELOPMENT === "pilot"
      ? pilotRequestCoachIntent
      : prodRequestCoachIntent;

const createComment =
  process.env.NEXT_PUBLIC_DEVELOPMENT === "development"
    ? testcreateComment
    : process.env.NEXT_PUBLIC_DEVELOPMENT === "pilot"
      ? pilotcreateComment
      : prodcreateComment;

const getComments =
  process.env.NEXT_PUBLIC_DEVELOPMENT === "development"
    ? testGetComments
    : process.env.NEXT_PUBLIC_DEVELOPMENT === "pilot"
      ? pilotGetComments
      : prodGetComments;

const getRating =
  process.env.NEXT_PUBLIC_DEVELOPMENT === "development"
    ? testGetRating
    : process.env.NEXT_PUBLIC_DEVELOPMENT === "pilot"
      ? pilotGetRating
      : prodGetRating;

const likeComment =
  process.env.NEXT_PUBLIC_DEVELOPMENT === "development"
    ? testLikeComment
    : process.env.NEXT_PUBLIC_DEVELOPMENT === "pilot"
      ? pilotLikeComment
      : prodLikeComment;


const createApplication =
  process.env.NEXT_PUBLIC_DEVELOPMENT === "development"
    ? testCreateApplication
    : process.env.NEXT_PUBLIC_DEVELOPMENT === "pilot"
      ? pilotCreateApplication
      : prodCreateApplication;

const createParticipant =
  process.env.NEXT_PUBLIC_DEVELOPMENT === "development"
    ? testCreateParticipant
    : process.env.NEXT_PUBLIC_DEVELOPMENT === "pilot"
      ? pilotCreateParticipant
      : prodCreateParticipant;

const removeParticipant =
  process.env.NEXT_PUBLIC_DEVELOPMENT === "development"
    ? testRemoveParticipant
    : process.env.NEXT_PUBLIC_DEVELOPMENT === "pilot"
      ? pilotRemoveParticipant
      : prodRemoveParticipant;

const addRaceToParticipant =
  process.env.NEXT_PUBLIC_DEVELOPMENT === "development"
    ? testAddRaceToParticipant
    : process.env.NEXT_PUBLIC_DEVELOPMENT === "pilot"
      ? pilotAddRaceToParticipant
      : prodAddRaceToParticipant;

const addParticipantToRace =
  process.env.NEXT_PUBLIC_DEVELOPMENT === "development"
    ? testAddParticipantToRace
    : process.env.NEXT_PUBLIC_DEVELOPMENT === "pilot"
      ? pilotAddParticipantToRace
      : prodAddParticipantToRace;

const removeParticipantFromRace =
  process.env.NEXT_PUBLIC_DEVELOPMENT === "development"
    ? testRemoveParticipantFromRace
    : process.env.NEXT_PUBLIC_DEVELOPMENT === "pilot"
      ? pilotRemoveParticipantFromRace
      : prodRemoveParticipantFromRace;

const getAllParticipants =
  process.env.NEXT_PUBLIC_DEVELOPMENT === "development"
    ? testGetAllParticipants
    : process.env.NEXT_PUBLIC_DEVELOPMENT === "pilot"
      ? pilotGetAllParticipants
      : prodGetAllParticipants;

const getParticipantRaceRegistrations =
  process.env.NEXT_PUBLIC_DEVELOPMENT === "development"
    ? testGetParticipantRaceRegistrations
    : process.env.NEXT_PUBLIC_DEVELOPMENT === "pilot"
      ? pilotGetParticipantRaceRegistrations
      : prodGetParticipantRaceRegistrations;

const getRegistrationData =
  process.env.NEXT_PUBLIC_DEVELOPMENT === "development"
    ? testGetRegistrationData
    : process.env.NEXT_PUBLIC_DEVELOPMENT === "pilot"
      ? pilotGetRegistrationData
      : prodGetRegistrationData;

const NewPartnerRequest =
  process.env.NEXT_PUBLIC_DEVELOPMENT === "development"
    ? testNewPartnerRequest
    : process.env.NEXT_PUBLIC_DEVELOPMENT === "pilot"
      ? pilotNewPartnerRequest
      : prodNewPartnerRequest;

const GetOrganizerContactInfo =
  process.env.NEXT_PUBLIC_DEVELOPMENT === "development"
    ? testGetOrganizerContactInfo
    : process.env.NEXT_PUBLIC_DEVELOPMENT === "pilot"
      ? pilotGetOrganizerContactInfo
      : prodGetOrganizerContactInfo;


const GetAllUsers =
  process.env.NEXT_PUBLIC_DEVELOPMENT === "development"
    ? testGetAllUsers
    : process.env.NEXT_PUBLIC_DEVELOPMENT === "pilot"
      ? pilotGetAllUsers
      : prodGetAllUsers;

const SendFriendRequest =
  process.env.NEXT_PUBLIC_DEVELOPMENT === "development"
    ? testSendFriendRequest
    : process.env.NEXT_PUBLIC_DEVELOPMENT === "pilot"
      ? pilotSendFriendRequest
      : prodSendFriendRequest;

const HandleFriendRequest =
  process.env.NEXT_PUBLIC_DEVELOPMENT === "development"
    ? testHandleFriendRequest
    : process.env.NEXT_PUBLIC_DEVELOPMENT === "pilot"
      ? pilotHandleFriendRequest
      : prodHandleFriendRequest;

const GetUserInformation =
  process.env.NEXT_PUBLIC_DEVELOPMENT === "development"
    ? testGetUserInformation
    : process.env.NEXT_PUBLIC_DEVELOPMENT === "pilot"
      ? pilotGetUserInformation
      : prodGetUserInformation;

const GetBasicUserInformation =
  process.env.NEXT_PUBLIC_DEVELOPMENT === "development"
    ? testGetBasicUserInformation
    : process.env.NEXT_PUBLIC_DEVELOPMENT === "pilot"
      ? pilotGetBasicUserInformation
      : prodGetBasicUserInformation;

const GetAllCoaches =
  process.env.NEXT_PUBLIC_DEVELOPMENT === "development"
    ? testGetAllCoaches
    : process.env.NEXT_PUBLIC_DEVELOPMENT === "pilot"
      ? pilotGetAllCoaches
      : prodGetAllCoaches;

const GetCoachData =
  process.env.NEXT_PUBLIC_DEVELOPMENT === "development"
    ? testGetCoachData
    : process.env.NEXT_PUBLIC_DEVELOPMENT === "pilot"
      ? pilotGetCoachData
      : prodGetCoachData;

const NewOrderedOffer =
  process.env.NEXT_PUBLIC_DEVELOPMENT === "development"
    ? testNewOrderedOffer
    : process.env.NEXT_PUBLIC_DEVELOPMENT === "pilot"
      ? pilotNewOrderedOffer
      : prodNewOrderedOffer;

const sendConfirmRegistration =
  process.env.NEXT_PUBLIC_DEVELOPMENT === "development"
    ? testSendConfirmRegistration
    : process.env.NEXT_PUBLIC_DEVELOPMENT === "pilot"
      ? pilotSendConfirmRegistration
      : prodSendConfirmRegistration;

const getOrganizerData =
  process.env.NEXT_PUBLIC_DEVELOPMENT === "development"
    ? testGetOrganizerData
    : process.env.NEXT_PUBLIC_DEVELOPMENT === "pilot"
      ? pilotGetOrganizerData
      : prodGetOrganizerData;

const addRaceReference =
  process.env.NEXT_PUBLIC_DEVELOPMENT === "development"
    ? testAddRaceReference
    : process.env.NEXT_PUBLIC_DEVELOPMENT === "pilot"
      ? pilotAddRaceReference
      : prodAddRaceReference;

const addOwnedRun =
  process.env.NEXT_PUBLIC_DEVELOPMENT === "development"
    ? testAddOwnedRun
    : process.env.NEXT_PUBLIC_DEVELOPMENT === "pilot"
      ? pilotAddOwnedRun
      : prodAddOwnedRun;

const addRaceToOrganizer =
  process.env.NEXT_PUBLIC_DEVELOPMENT === "development"
    ? testAddRaceToOrganizer
    : process.env.NEXT_PUBLIC_DEVELOPMENT === "pilot"
      ? pilotAddRaceToOrganizer
      : prodAddRaceToOrganizer;

const updateOwnedRun =
  process.env.NEXT_PUBLIC_DEVELOPMENT === "development"
    ? testUpdateOwnedRun
    : process.env.NEXT_PUBLIC_DEVELOPMENT === "pilot"
      ? pilotUpdateOwnedRun
      : prodUpdateOwnedRun;

const getListOfUsers =
  process.env.NEXT_PUBLIC_DEVELOPMENT === "development"
    ? testGetListOfUsers
    : process.env.NEXT_PUBLIC_DEVELOPMENT === "pilot"
      ? pilotGetListOfUsers
      : prodGetListOfUsers;

const GetLatestComments =
  process.env.NEXT_PUBLIC_DEVELOPMENT === "development"
    ? testGetLatestComments
    : process.env.NEXT_PUBLIC_DEVELOPMENT === "pilot"
      ? pilotGetLatestComments
      : prodGetLatestComments;

const AddNewParticipant =
  process.env.NEXT_PUBLIC_DEVELOPMENT === "development"
    ? testAddNewParticipant
    : process.env.NEXT_PUBLIC_DEVELOPMENT === "pilot"
      ? pilotAddNewParticipant
      : prodAddNewParticipant;

const RemoveParticipantCF =
  process.env.NEXT_PUBLIC_DEVELOPMENT === "development"
    ? testRemoveParticipantCF
    : process.env.NEXT_PUBLIC_DEVELOPMENT === "pilot"
      ? pilotRemoveParticipantCF
      : prodRemoveParticipantCF;

const MostViewed =
  process.env.NEXT_PUBLIC_DEVELOPMENT === "development"
    ? testMostViewed
    : process.env.NEXT_PUBLIC_DEVELOPMENT === "pilot"
      ? pilotMostViewed
      : prodMostViewed;

const activateOrganizerRole =
  process.env.NEXT_PUBLIC_DEVELOPMENT === "development"
    ? testActivateOrganizerRole
    : process.env.NEXT_PUBLIC_DEVELOPMENT === "pilot"
      ? pilotActivateOrganizerRole
      : prodActivateOrganizerRole;

const searchUsers =
  process.env.NEXT_PUBLIC_DEVELOPMENT === "development"
    ? testSearchUsers
    : process.env.NEXT_PUBLIC_DEVELOPMENT === "pilot"
      ? pilotSearchUsers
      : prodSearchUsers;

const suggestedFriends =
  process.env.NEXT_PUBLIC_DEVELOPMENT === "development"
    ? testSuggestedFriends
    : process.env.NEXT_PUBLIC_DEVELOPMENT === "pilot"
      ? pilotSuggestedFriends
      : prodSuggestedFriends;

const getAllRuns =
  process.env.NEXT_PUBLIC_DEVELOPMENT === "development"
    ? testGetAllRuns
    : process.env.NEXT_PUBLIC_DEVELOPMENT === "pilot"
      ? pilotGetAllRuns
      : prodGetAllRuns;

const getCountRuns =
  process.env.NEXT_PUBLIC_DEVELOPMENT === "development"
    ? testGetCountRuns
    : process.env.NEXT_PUBLIC_DEVELOPMENT === "pilot"
      ? pilotGetCountRuns
      : prodGetCountRuns;

const findRuns =
  process.env.NEXT_PUBLIC_DEVELOPMENT === "development"
    ? testFindRuns
    : process.env.NEXT_PUBLIC_DEVELOPMENT === "pilot"
      ? pilotFindRuns
      : prodFindRuns;

const urlChecker =
  process.env.NEXT_PUBLIC_DEVELOPMENT === "development"
    ? testUrlChecker
    : process.env.NEXT_PUBLIC_DEVELOPMENT === "pilot"
      ? pilotUrlChecker
      : prodUrlChecker;

const getPartners =
  process.env.NEXT_PUBLIC_DEVELOPMENT === "development"
    ? testGetPartners
    : process.env.NEXT_PUBLIC_DEVELOPMENT === "pilot"
      ? pilotGetPartners
      : prodGetPartners;

const getCountries =
  process.env.NEXT_PUBLIC_DEVELOPMENT === "development"
    ? testGetCountries
    : process.env.NEXT_PUBLIC_DEVELOPMENT === "pilot"
      ? pilotGetCountries
      : prodGetCountries;

const getSubmenuData =
  process.env.NEXT_PUBLIC_DEVELOPMENT === "development"
    ? testGetSubmenuData
    : process.env.NEXT_PUBLIC_DEVELOPMENT === "pilot"
      ? pilotGetSubmenuData
      : prodGetSubmenuData;

const getRunTitles =
  process.env.NEXT_PUBLIC_DEVELOPMENT === "development"
    ? testGetRunTitles
    : process.env.NEXT_PUBLIC_DEVELOPMENT === "pilot"
      ? pilotGetRunTitles
      : prodGetRunTitles

const getMapData =
  process.env.NEXT_PUBLIC_DEVELOPMENT === "development"
    ? testGetMapdata
    : process.env.NEXT_PUBLIC_DEVELOPMENT === "pilot"
      ? pilotGetMapdata
      : prodGetMapdata

const getProgram =
  process.env.NEXT_PUBLIC_DEVELOPMENT === "development"
    ? testGetProgram
    : process.env.NEXT_PUBLIC_DEVELOPMENT === "pilot"
      ? pilotGetProgram
      : prodGetProgram

const updateLikes =
  process.env.NEXT_PUBLIC_DEVELOPMENT === "development"
    ? testUpdateLikes
    : process.env.NEXT_PUBLIC_DEVELOPMENT === "pilot"
      ? pilotUpdateLikes
      : prodUpdateLikes

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  donationIntentUrl,
  updateDonationUrl,
  createOrderUrl,
  coachIntentUrl,
  createComment,
  getComments,
  getRating,
  likeComment,
  createApplication,
  createParticipant,
  removeParticipant,
  addRaceToParticipant,
  addParticipantToRace,
  removeParticipantFromRace,
  getAllParticipants,
  getParticipantRaceRegistrations,
  getRegistrationData,
  NewPartnerRequest,
  GetAllUsers,
  GetOrganizerContactInfo,
  SendFriendRequest,
  HandleFriendRequest,
  GetUserInformation,
  GetBasicUserInformation,
  GetAllCoaches,
  GetCoachData,
  NewOrderedOffer,
  sendConfirmRegistration,
  getOrganizerData,
  addRaceReference,
  addOwnedRun,
  addRaceToOrganizer,
  updateOwnedRun,
  GetLatestComments,
  getListOfUsers,
  AddNewParticipant,
  RemoveParticipantCF,
  MostViewed,
  activateOrganizerRole,
  searchUsers,
  suggestedFriends,
  getAllRuns,
  getCountRuns,
  findRuns,
  urlChecker,
  getPartners,
  getCountries,
  getSubmenuData,
  getRunTitles,
  getMapData,
  getProgram,
  updateLikes
};
