
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import "@/styles/globals.css";
import type { AppProps } from "next/app";
import "@/styles/index.scss";
import initAuth from "@/components/Firebase/initAuth";
import Firebase, { FirebaseContext } from "@/components/Firebase";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import dynamic from "next/dynamic";
import { SharedStateProvider } from "@/helpers/metricContext";
const Layout: any = dynamic(() => import("@/components/layout/layout"));
const Search: any = dynamic(() => import("@/components/Firebase/Search"));
const NextNProgress: any = dynamic(() => import("nextjs-progressbar"));
initAuth();
const App = ({ Component, pageProps }: AppProps) => {
    const [initialized, set_initialized] = useState(false);
    const router = useRouter();
    const initOneSignal = async () => {
        if (!initialized) {
            set_initialized(true);
            try {
                const OneSignal = (await import("react-onesignal")).default;
                await OneSignal.init({
                    appId: process.env.NEXT_PUBLIC_DEVELOPMENT === "development"
                        ? "c33afcbb-c7a9-4a5d-8833-be6cce69ce90"
                        : "e73ee429-3306-4d2e-a9f3-5b34b19af908",
                    safari_web_id: "web.onesignal.auto.1ee85315-99d2-4859-abe2-d0d2d86b4cd7",
                    allowLocalhostAsSecureOrigin: true,
                    promptOptions: [{ pageViews: 2, timeDelay: 10 }],
                });
                OneSignal.showSlidedownPrompt();
            }
            catch { }
        }
    };
    useEffect(() => {
        initOneSignal();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); // <-- run this effect once on mount
    return (<>
            <NextNProgress showOnShallow={true} height={5} nonce="my-nonce" color="#FF8640"/>
            <FirebaseContext.Provider value={new Firebase()}>
                <Search>
                    <SharedStateProvider>
                        <Layout>
                            <Component {...pageProps} key={router.asPath}/>
                        </Layout>
                    </SharedStateProvider>
                </Search>
            </FirebaseContext.Provider>
        </>);
};
const __Next_Translate__Page__193c17a8784__ = App;

    export default __appWithI18n(__Next_Translate__Page__193c17a8784__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
    });
  