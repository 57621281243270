import { initializeApp } from "firebase/app";
import {
    getAuth,
    onAuthStateChanged,
    GoogleAuthProvider,
    FacebookAuthProvider,
    EmailAuthProvider,
    signInWithPopup,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    fetchSignInMethodsForEmail,
    sendPasswordResetEmail,
    updatePassword,
    getRedirectResult,
    Auth,
    updateProfile,
    signInWithCredential,
} from "firebase/auth";
// import { getAnalytics } from "firebase/analytics";
import {
    getStorage,
    uploadBytes,
    getDownloadURL,
    getMetadata,
    listAll,
    ref as storageRef,
    getBlob,
    getStream,
    deleteObject,
} from "firebase/storage";
import firebaseConfig from "./firebaseConfig";
import { CustomEvent } from "@piwikpro/react-piwik-pro";
import { OrderAfterArray, RemoveItemFromList } from "@/helpers/arrayHelper";
import { GetAllRunsRequest } from "@/HttpCalls/runs/GetAllRunsRequest";
import { sleep } from "@/helpers/sleep";
import TagManager from "react-gtm-module";
import { mutate } from "swr";
import router from "next/router";
import { PROFILE } from "@/constants/routes";
import {
    addDocument,
    addReference,
    deleteDocument,
    getAllDocumentWithQuery,
    getCollectionData,
    getDocument,
    getDynamicQuery,
    have_participated,
    updateDocument,
} from "./firebaseHttp";
import { get_activities } from "@/HttpCalls/runs/userEngagement";
import { getBlog } from "@/HttpCalls/blogs/getBlogs";

declare global {
    interface Window {
        FB?: {
            login?: (callback: any) => Promise<void>;
        }; // Adjust the type to match the external function
    }
}

class Firebase {
    public app: any;
    public auth: Auth;
    public currentUser: any;
    public favouriteRuns: any;
    public storage: any;
    // public analytics: any;
    public googleAuthProvider: GoogleAuthProvider;
    public facebookAuthProvider: any;
    public emailAuthProvider: any;
    public initiated: any;
    public tagManagersArgs: any;

    constructor() {
        this.app = initializeApp(firebaseConfig);
        // if (typeof window !== "undefined") {
        //     this.analytics = getAnalytics(this.app);
        // }
        this.auth = getAuth();
        this.storage = getStorage();
        // this.fireStore = initializeFirestore(this.app, {
        //     experimentalForceLongPolling: true
        // })
        this.currentUser = {};
        this.favouriteRuns = "";

        /* Social Sign In Method Provider */
        this.googleAuthProvider = new GoogleAuthProvider();

        this.facebookAuthProvider = new FacebookAuthProvider();
        this.facebookAuthProvider.setCustomParameters({ display: "popup" });
        this.emailAuthProvider = new EmailAuthProvider();
        this.initiated = false;

        this.tagManagersArgs = {
            dataLayer: {
                event: null,
                user_id: null,
                username: null,
                email: null,
                user_fullname: null,
                user_birth_year: null,
                user_gender: null,
                user_place: null,
                user_country: null,
                user_num_participated: null,
                user_num_favorites: null,
                user_num_comments: null,
                user_num_friends_pending: null,
                user_num_friends: null,
                user_is_admin: null,
                user_is_organiser: null,
            },
        };

        onAuthStateChanged(this.auth, async (authUser: any) => {
            this.initiated = true;
            if (authUser !== null) {
                const user_id = authUser.uid;
                const token = authUser.accessToken;
                const response = await getDocument("Users", user_id, token);
                const dbUser: any = response.data;
                const roleDefined = dbUser?.role !== null ? true : false;
                // default empty roles
                try {
                    if (!roleDefined) {
                        dbUser.role = [];
                    }
                } catch (e) {
                    console.log(e);
                }

                // merge auth and db user
                authUser = {
                    uid: authUser.uid,
                    email: authUser.email,
                    emailVerified: authUser.emailVerified,
                    providerData: authUser.providerData,
                    ...dbUser,
                };
                this.currentUser = authUser;
                this.favouriteRuns = authUser.favouriteRuns;
            }
        });
    }

    handleRedirectResults = async () => {
        const result = await getRedirectResult(this.auth);
        if (result) {
            const newUser =
                result.user.metadata?.creationTime === result.user.metadata.lastSignInTime ||
                (result.user.metadata.creationTime !== undefined &&
                    Math.abs(Number(result.user.metadata.creationTime) - new Date().getTime()) <
                        30000)
                    ? true
                    : false;
            for (let counter = 0; counter <= 5; counter++) {
                const user_data = await this.getDocument("Users", result.user.uid);
                if (user_data && Object.keys(user_data).length > 0) {
                    user_data["uid"] = result.user.uid;
                    if (newUser && typeof window !== "undefined") {
                        this.logSignedIn(user_data, "register_account");
                    }
                    // setTimeout
                    mutate("userData", user_data, false);
                    if (router.pathname.includes("signup") || router.pathname.includes("login")) {
                        router.push(PROFILE);
                    }
                    break;
                } else {
                    await sleep(500);
                }
            }
        }
    };

    checkForSavedFilter = () => {
        const filterOption = sessionStorage.getItem("filterOptions");
        if (filterOption) {
            return true;
        }
    };

    // tryAgain = async (body: any) => {
    //     for (let counter = 0; counter <= 5; counter++) {
    //         try {
    //             counter += 1;
    //             await this.updateUser(body);
    //             break;
    //         } catch (e: any) {
    //             await sleep(500);
    //         }
    //     }
    // };

    // *** Auth API ***
    getIdtokenWithretry = async () => {
        for (let counter = 0; counter <= 10; counter++) {
            try {
                counter += 1;
                if (this.auth.currentUser !== undefined) {
                    const token = await this.auth.currentUser?.getIdToken();
                    if (token !== undefined) {
                        return token;
                    } else {
                        await sleep(500);
                    }
                } else {
                    await sleep(500);
                }
            } catch (e: any) {
                await sleep(500);
            }
        }
        return null;
    };

    customSignIn = async (loginMethod: string) => {
        switch (loginMethod) {
            case "Google":
                await signInWithPopup(this.auth, this.googleAuthProvider);
                break;
            case "FaceBook":
                //ts-ignore
                if (typeof window !== "undefined" && window?.FB?.login) {
                    window?.FB?.login(function (response: any) {
                        if (response) {
                            const credential = FacebookAuthProvider.credential(
                                response?.authResponse?.accessToken
                            );
                            const auth = getAuth();
                            signInWithCredential(auth, credential);
                        }
                    });
                }
                break;
        }
    };

    checkIfEmailExists = async (email: string) => {
        const result = await fetchSignInMethodsForEmail(this.auth, email);
        if (result.length > 0 && result[0] === "password") {
            return true;
        } else {
            return false;
        }
    };

    doSignInWithEmailAndPassword = async (email: string, password: string) => {
        try {
            const result = await signInWithEmailAndPassword(this.auth, email, password);
            return result;
        } catch (e) {
            return null;
        }
    };

    doCreateUserWithEmailAndPassword = async (email: string, password: string) => {
        const result = await createUserWithEmailAndPassword(this.auth, email, password);
        if (result) {
            const newUser =
                result.user.metadata?.creationTime === result.user.metadata.lastSignInTime ||
                (result.user.metadata.creationTime !== undefined &&
                    Math.abs(Number(result.user.metadata.creationTime) - new Date().getTime()) <
                        30000)
                    ? true
                    : false;

            if (newUser) {
                for (let counter = 0; counter <= 5; counter++) {
                    const user_data = await this.getDocument("Users", result.user.uid);
                    if (user_data && Object.keys(user_data).length > 0) {
                        user_data["uid"] = result.user.uid;
                        this.logSignedIn(user_data, "register_account");
                        return result.user.uid;
                    } else {
                        await sleep(500);
                    }
                }
            }

            return result.user.uid;
        } else {
            return false;
        }
    };

    doPasswordReset = async (email: string) => {
        const result = await this.checkIfEmailExists(email);
        try {
            if (result) {
                await sendPasswordResetEmail(this.auth, email);
                return true;
            } else {
                return false;
            }
        } catch {
            return false;
        }
    };
    doPasswordUpdate = async (newPassword: string) => {
        try {
            if (this.auth.currentUser) await updatePassword(this.auth?.currentUser, newPassword);
            return true;
        } catch (error: any) {
            if (error?.code == "auth/requires-recent-login") {
                return { promtForCredentials: true };
            } else {
                return false;
            }
        }
    };

    doSignInWithGoogle = async () => {
        return await signInWithPopup(this.auth, this.googleAuthProvider);
    };

    doSignInWithFacebook = async () => await signInWithPopup(this.auth, this.facebookAuthProvider);

    doSignOut = async () => {
        this.tagManagersArgs["dataLayer"]["event"] = "sign_out";
        this.logFirebaseEvent(this.tagManagersArgs);
        this.currentUser = null;
        await this.auth.signOut();
    };

    // *** User API ***

    getAllRuns = async () => {
        let fetchedData = false;
        let temp: any[] = [];
        let counter = 0;
        while (!fetchedData && counter < 7) {
            counter++;
            try {
                temp = [];
                let result;

                const response = await GetAllRunsRequest();

                if (response.status === 200) {
                    result = response.data;
                }

                for (const document of result) {
                    const fields = document;
                    if (
                        fields?.race_type?.includes("Repeterende") &&
                        Array.isArray(fields.date) &&
                        (fields.post_status === "publish" || fields.post_status === "pending")
                    ) {
                        const tempData = {
                            id: fields.race_guid,
                            race_guid: fields.race_guid,
                            post_title: fields.post_title,
                            post_status: fields.post_status,
                            date: fields.date[0]?.date,
                            race_type: fields?.race_type ?? [],
                            place: fields?.country,
                            last_checked: fields.last_checked,
                            last_updated: fields.last_updated,
                        };
                        if (fields.date?.length > 1) {
                            fields.date
                                .sort(function (a: any, b: any) {
                                    const btime = b.date.split(".");
                                    const atime = a.date.split(".");
                                    return (
                                        new Date(btime[2], btime[1] - 1, btime[0]).getTime() -
                                        new Date(atime[2], atime[1] - 1, atime[0]).getTime()
                                    );
                                })
                                .reverse();
                            tempData.date =
                                fields.date[0].date + " – " + fields.date.slice(-1)[0].date;
                        }
                        temp.push(tempData);
                    } else if (
                        fields.post_status === "publish" ||
                        fields.post_status === "pending"
                    ) {
                        const tempData = {
                            id: fields.race_guid,
                            race_guid: fields.race_guid,
                            post_title: fields.post_title,
                            post_status: fields.post_status,
                            date: fields.date,
                            date_end: fields?.date_end,
                            timestamp: fields?.timestamp,
                            timestamp_end: fields?.timestamp_end,
                            race_type: fields?.race_type ?? [],
                            place: fields?.country,
                            last_checked: fields.last_checked,
                            last_updated: fields.last_updated,
                        };
                        temp.push(tempData);
                    }
                }

                if (temp.length > 0) {
                    fetchedData = true;
                }
            } catch (e) {
                // TODO: Need to handle this in a good way
                console.log(e);
            }
        }

        return temp;
    };


    updateUser = async (data: any, uid?: string) => {
        const token = await this.getIdtokenWithretry();

        await updateDocument("Users", uid ? uid : this.currentUser.uid, data, token);

        if (this.auth.currentUser) {
            updateProfile(this.auth?.currentUser, {
                displayName: data["firstName"],
            });
        }

        this.updateOnCurrentUser(uid);
    };

    updateOrders = async (value: any, uid?: string) => {
        const token = await this.getIdtokenWithretry();
        const response = await getDocument("Users", uid ? uid : this.currentUser.uid, token);
        const new_data = {
            orders: [...response.data["orders"], value],
        };
        await updateDocument("Users", uid ? uid : this.currentUser.uid, new_data, token);

        this.updateOnCurrentUser(uid);
    };

    logFirebaseEvent = (
        tagManagersArgs: any,
        eventName?: string,
        event?: boolean,
        category?: string
    ) => {
        if (eventName && event && category !== undefined && typeof window !== "undefined") {
            CustomEvent.trackEvent(category, eventName);
        } else if (tagManagersArgs !== null) {
            TagManager.dataLayer(tagManagersArgs);
        }
    };

    logPageViewEvent = (page_title: string) => {
        const data = {
            dataLayer: {
                event: "page_views",
                page_title: page_title,
            },
        };

        TagManager.dataLayer(data);
    };

    uploadImage = async (filePath: string, blob: Blob) => {
        const imageRef = storageRef(this.storage, filePath);
        const metadata = {
            contentType: blob.type,
        };
        await uploadBytes(imageRef, blob, metadata);

        return await getDownloadURL(imageRef);
    };

    uploadImageWithMetaData = async (filePath: string, blob: Blob, metadata: any) => {
        const imageRef = storageRef(this.storage, filePath);
        await uploadBytes(imageRef, blob, metadata);
    };

    getImageRefs = (filePath: string) => {
        const imageRef = storageRef(this.storage, filePath);
        return listAll(imageRef);
    };

    getImageUrls = (files: any[]) => {
        const list: any[] = [];
        files.forEach((imageRef: any) => {
            list.push(getDownloadURL(imageRef));
        });
        return Promise.all(list).then((e: any) => e);
    };

    getImage = async (filePath: string) => {
        const imageRef = storageRef(this.storage, filePath);
        try {
            return await getDownloadURL(imageRef);
        } catch {
            return "";
        }
    };

    getUrl = async (fileRef: any) => {
        try {
            return await getDownloadURL(fileRef);
        } catch {
            return "";
        }
    };

    getDeals = async (token: string) => {
        const response = await getCollectionData("Deals", token);
        if (response.status === 200 && response.data !== false) {
            return response.data;
        } else {
            return [];
        }
    };

    getFile = (filePath: string) => {
        const fileRef = storageRef(this.storage, filePath);
        return getBlob(fileRef);
    };

    getStreamData = (filePath: string) => {
        const fileRef = storageRef(this.storage, filePath);
        return getStream(fileRef);
    };

    deleteFirestoreFile = async (filePath: string, document_id: string) => {
        const token = await this.getIdtokenWithretry();
        const response = await deleteDocument(filePath, document_id, token);
        return response.data;
    };

    deleteFile = async (fileRef: any) => {
        try {
            await deleteObject(fileRef);
            return true;
        } catch {
            return false;
        }
    };

    getImageMetadata = (files: any[]) => {
        const list: any[] = [];
        files.forEach((imageRef: any) => {
            list.push(getMetadata(imageRef));
        });
        return Promise.all(list).then((e: any) => e);
    };

    addToFavouriteList = async (postUrl: string) => {
        this.favouriteRuns += ";" + postUrl;

        let new_data = {
            favouriteRuns: this.favouriteRuns,
        };
        const token = await this.getIdtokenWithretry();
        const user_id = this.auth.currentUser?.uid ?? "";
        await updateDocument("Users", user_id, new_data, token);
        this.updateOnCurrentUser();
    };

    addFeedback = async (data: any) => {
        await addDocument("Feedback", data);
    };

    addClaimEventDocument = async (data: any) => {
        await addDocument("ClaimEvent", data);
    };

    removeFromeFavouriteList = async (postUrl: string) => {
        this.favouriteRuns = this.favouriteRuns.replaceAll(";" + postUrl, "");

        let new_data = {
            favouriteRuns: this.favouriteRuns,
        };
        const token = await this.getIdtokenWithretry();
        const user_id = this.auth.currentUser?.uid ?? "";
        await updateDocument("Users", user_id, new_data, token);
        this.updateOnCurrentUser();
    };

    getAllDocuments = async (filePath: string) => {
        const token = await this.getIdtokenWithretry();
        const response = await getCollectionData(filePath, token);
        if (response.status === 200 && response.data !== false) {
            return response.data;
        } else {
            return [];
        }
    };

    // TODO: Look at orderby later
    getAllDocumentsWithPagenation = async (
        filePath: string,
        last_document: any,
        limit_number: number
    ) => {
        if (last_document) {
            const response = await getDynamicQuery(
                filePath,
                undefined,
                "timestamp",
                "desc",
                limit_number,
                last_document,
                undefined
            );
            return response.data;
        } else {
            const response = await getDynamicQuery(
                filePath,
                undefined,
                "timestamp",
                "desc",
                limit_number,
                undefined,
                undefined
            );
            return response.data;
        }
    };

    getTopBlogs = async () => {
        const response = await getBlog(9);
        return response.data;
    };

    getCustomClaim = async (ClaimName: string) => {
        const id = await this.auth.currentUser?.getIdTokenResult();
        return id?.claims[ClaimName];
    };

    getNextBlogs = async (startPosition: any) => {
        const response = await getBlog(9, startPosition);
        return response.data;
    };

    getDocument = async (filePath: string, documentName: string) => {
        const token = await this.getIdtokenWithretry();
        const response = await getDocument(filePath, documentName, token);
        if (response.status === 200 && response.data != false) {
            return response.data;
        } else {
            return {};
        }
    };

    getDocumentWithQuery = async (
        collectionName: string,
        field: string,
        matchString: any,
        queryString: any
    ) => {
        const token = await this.getIdtokenWithretry();
        const response = await getAllDocumentWithQuery(
            collectionName,
            matchString,
            field,
            queryString,
            token
        );
        if (response.status === 200 && response.data != false) {
            return response.data;
        } else {
            return [];
        }
    };

    addRaceReference = async () => {
        const token = await this.getIdtokenWithretry();
        const response = await addReference("RunCollection", token);
        if (response.status === 200 && response.data != false) {
            return response.data;
        } else {
            return {};
        }
    };

    addNewRace = async (filePath: string, data: any) => {
        const token = await this.getIdtokenWithretry();
        const response = await updateDocument("RunCollection", filePath, data, token);
        if (response.status === 200 && response.data != false) {
            return true;
        } else {
            return false;
        }
    };

    addNewDocument = async (filePath: string, data: any) => {
        const token = await this.getIdtokenWithretry();
        const response = await addDocument(filePath, data, token);
        if (response.status === 200 && response.data != false) {
            return true;
        } else {
            return false;
        }
    };

    updateDocument = async (filePath: string, document_id: string, data: any) => {
        const token = await this.getIdtokenWithretry();
        const response = await updateDocument(filePath, document_id, data, token);
        if (response.status === 200 && response.data != false) {
            return true;
        } else {
            return false;
        }
    };

    getMasterclass = async () => {
        const token = await this.getIdtokenWithretry();
        const response = await getDynamicQuery(
            "masterclass-portal",
            undefined,
            "order",
            undefined,
            undefined,
            undefined,
            token
        );
        return response.data;
    };

    getMasterclassModule = async (course: string) => {
        const token = await this.getIdtokenWithretry();
        const response = await getDynamicQuery(
            `masterclass-portal/${course}/modules`,
            undefined,
            "order",
            undefined,
            undefined,
            undefined,
            token
        );
        return response.data;
    };

    addNewActivity = async (data: any, year?: number) => {
        const token = await this.getIdtokenWithretry();
        const response = await addDocument("Activity", data, token);

        const activityId = response.data;
        if (activityId && data.activityType === 2 && year) {
            const participate: any = { participate: {} };
            if (Object.prototype.hasOwnProperty.call(this.currentUser, "participate")) {
                participate["participate"] = { ...this.currentUser["participate"] };
            }
            if (Object.prototype.hasOwnProperty.call(participate["participate"], year)) {
                participate["participate"][year].push(activityId);
            } else {
                participate["participate"][year] = [activityId];
            }

            this.updateUser(participate);
            await this.updateOnCurrentUser();
        }
    };

    removeParticipation = async (activityId: any, year: number) => {
        const participate: any = { participate: { year: [] } };
        if (Object.prototype.hasOwnProperty.call(this.currentUser, "participate")) {
            participate["participate"] = { ...this.currentUser["participate"] };
        }
        try {
            participate["participate"][year] = RemoveItemFromList(
                activityId,
                participate["participate"][year]
            );
        } catch (e) {
            console.log(e);
        }

        this.updateUser(participate);
        this.updateOnCurrentUser();
    };

    getActivityId = async (run_guid: string, owner: string) => {
        const token = await this.getIdtokenWithretry();
        const response = await have_participated(owner, run_guid);
        return response.data;
    };

    getActivities = async (activityIds: string[]) => {
        let returnDocs: any = [];
        const token = await this.getIdtokenWithretry();
        const chunkSize = 10;
        for (let i = 0; i < activityIds.length; i += chunkSize) {
            const chunk = activityIds.slice(i, i + chunkSize);
            const response = await get_activities(chunk, token);
            returnDocs.push(...response.data);
        }
        returnDocs = returnDocs.map((item: any) => {
            item["id"] = item["_firestore_id"];
            return item;
        });
        returnDocs = OrderAfterArray(returnDocs, activityIds);
        return returnDocs;
    };

    readAlert = async (activityId: string) => {
        const data = this.currentUser?.alerts;
        if (data.un_read.includes(activityId)) {
            data.un_read = RemoveItemFromList(activityId, data.un_read);
            await this.updateUser({ alerts: data });
            await this.updateOnCurrentUser();
        }
    };

    readAllAlerts = async () => {
        const data = this.currentUser.alerts;
        data.un_read = [];
        await this.updateUser({ alerts: data });
        await this.updateOnCurrentUser();
    };

    addCheerOnActivity = async (activityId: string, username: string) => {
        const token = await this.getIdtokenWithretry();
        const data_response = await getDocument("Activity", activityId, token);
        let data = data_response.data;
        const tempCheers = data.data.cheers;
        tempCheers.push(username);
        data.data.cheers = [...new Set(tempCheers)];
        await updateDocument("Activity", activityId, data, token);
        await this.updateOnCurrentUser();
    };

    addSignup = async (data: any) => {
        await addDocument("SignupNews", data);
    };

    removeCheerOnActivity = async (activityId: string, username: string) => {
        const token = await this.getIdtokenWithretry();
        const data_response = await getDocument("Activity", activityId, token);
        let data = data_response.data;

        let tempCheers = data.data.cheers;
        tempCheers = RemoveItemFromList(username, tempCheers);
        data.data.cheers = [...new Set(tempCheers)];
        await updateDocument("Activity", activityId, data, token);
        await this.updateOnCurrentUser();
    };

    updateOnRace = async (filePath: string, data: any) => {
        const token = await this.getIdtokenWithretry();
        await updateDocument("RunCollection", filePath, data, token);
    };

    checkedRace = async (filePath: string, data: any) => {
        const token = await this.getIdtokenWithretry();
        await updateDocument("RunCollection", filePath, { last_checked: data }, token);
    };

    updateOnCurrentUser = async (uid?: string) => {
        const token = await this.getIdtokenWithretry();
        const user_id = this.auth.currentUser?.uid ?? "";
        if (token) {
            const response = await getDocument("Users", user_id, token);
            const dbUser: any = response.data;
            const roleDefined = dbUser?.roles !== null ? true : false;
            // default empty roles
            try {
                if (!roleDefined) {
                    dbUser.roles = [];
                }
            } catch (e) {
                console.log(e);
            }

            // merge auth and db user
            this.currentUser = {
                uid: this.currentUser.uid,
                email: this.currentUser.email,
                emailVerified: this.currentUser.emailVerified,
                providerData: this.currentUser.providerData,
                ...dbUser,
            };

            // localStorage.setItem("authUser", JSON.stringify(this.currentUser));
            this.tagManagersArgs = {
                dataLayer: {
                    event: "user_update_data",
                    user_id: this.currentUser.uid,
                    username: this.currentUser.username,
                    email: this.currentUser.email,
                    user_fullname:
                        this.currentUser.firstName !== "" &&
                        this.currentUser.firstName !== undefined
                            ? this.currentUser.firstName + " " + this.currentUser.lastName ?? ""
                            : null,
                    user_birth_year: this.currentUser.birthDate,
                    user_gender: this.currentUser.gender,
                    user_place: this.currentUser.place,
                    user_country: this.currentUser.country,
                    user_num_participated: Object.prototype.hasOwnProperty.call(
                        this.currentUser,
                        "participate"
                    )
                        ? this.currentUser.participate[new Date().getFullYear().toString()]
                              ?.length ?? 0
                        : 0,
                    user_num_favorites:
                        this.currentUser.favouriteRuns?.split(";").filter((n: any) => n).length ??
                        0,
                    user_num_comments: 0,
                    user_num_friends_pending:
                        this.currentUser.pendingFriendRequests?.split(";").filter((n: any) => n)
                            .length ?? 0,
                    user_num_friends:
                        this.currentUser.friends?.split(";").filter((n: any) => n).length ?? 0,
                    user_is_admin: this.currentUser.role?.includes("ADMIN") ?? false,
                    user_is_organiser: this.currentUser.role?.includes("ORGANIZER") ?? false,
                },
            };
            this.logFirebaseEvent(this.tagManagersArgs);
        }
    };

    refetchAuthToken = () => {
        this.auth.currentUser?.getIdToken(true);
    };

    logSignedIn = (authUser: any, eventName: string) => {
        const tagManagersArgs = {
            dataLayer: {
                event: eventName,
                user_id: authUser.uid,
                username: authUser.username,
                email: authUser.email,
                user_fullname:
                    authUser.firstName !== "" && authUser.firstName !== undefined
                        ? authUser.firstName + " " + authUser.lastName ?? ""
                        : null,
                user_birth_year: authUser.birthDate,
                user_gender: authUser.gender,
                user_place: authUser.place,
                user_country: authUser.country,
                user_num_participated: Object.prototype.hasOwnProperty.call(authUser, "participate")
                    ? authUser?.participate[new Date().getFullYear().toString()]?.length ?? 0
                    : 0,
                user_num_favorites:
                    authUser.favouriteRuns?.split(";").filter((n: any) => n).length ?? 0,
                user_num_comments: 0,
                user_num_friends_pending:
                    authUser.pendingFriendRequests?.split(";").filter((n: any) => n).length ?? 0,
                user_num_friends: authUser.friends?.split(";").filter((n: any) => n).length ?? 0,
                user_is_admin: authUser.role?.includes("ADMIN") ?? false,
                user_is_organiser: authUser.role?.includes("ORGANIZER") ?? false,
            },
        };

        this.logFirebaseEvent(tagManagersArgs);
        CustomEvent.trackEvent("user", "new_user");
    };

    // *** Merge Auth and DB User API *** //
    onAuthUserListener = (next: any, fallback: any) =>
        this.auth.onAuthStateChanged(async (authUser: any) => {
            this.handleRedirectResults();
            this.initiated = true;
            if (authUser && this.currentUser == null) {
                const user_id = authUser.uid;
                const token = authUser.accessToken;
                const response = await getDocument("Users", user_id, token);
                const dbUser: any = response.data;
                const roleDefined = dbUser?.roles !== null ? true : false;
                // default empty roles
                try {
                    if (!roleDefined) {
                        dbUser.roles = [];
                    }
                } catch (e) {
                    console.log(e);
                }

                // merge auth and db user
                authUser = {
                    uid: authUser.uid,
                    email: authUser.email,
                    emailVerified: authUser.emailVerified,
                    providerData: authUser.providerData,
                    ...dbUser,
                };
                this.currentUser = authUser;
                this.favouriteRuns = authUser.favouriteRuns;

                // TODO: Validate this
                if (this.checkForSavedFilter()) {
                    let filterOptions: any;
                    let viewOption: any;
                    let sortOption: any;
                    filterOptions = sessionStorage.getItem("filterOptions");
                    filterOptions = JSON.parse(filterOptions);
                    viewOption = sessionStorage.getItem("viewOption");
                    viewOption = JSON.parse(viewOption);
                    sortOption = sessionStorage.getItem("sortOption");
                    sortOption = JSON.parse(sortOption);
                    // TODO: Is this important
                    // const data = {
                    //     defaultFilterOptions: filterOptions,
                    //     defaultView: viewOption,
                    //     defaultSortOption: sortOption,
                    // };
                    // this.tryAgain(data);
                    sessionStorage.removeItem("filterOptions");
                    sessionStorage.removeItem("viewOption");
                    sessionStorage.removeItem("sortOption");
                }
            } else {
                fallback();
            }
        });
}
export default Firebase;
