const RemoveItemFromList = (item: any, array: any[]) => {
    const index = array.indexOf(item);
    if (index !== -1) {
        array.splice(index, 1);
    }
    return array;
};

const OrderAfterArray = (input: any[], orderArray: any[]) => {
    const returnArray: any[] = new Array(input.length);
    for (let i = 0; i < input.length; i++) {
        const value: any = input[i].id;
        const index: any = orderArray.indexOf(value);
        if (index !== -1) {
            returnArray[index] = input[i];
        }
    }
    return returnArray;
};

const RemoveDuplicates = (input: any[], match_prop: string) => {
    return input.filter(
        (value: any, index: number, self: any) =>
            index === self.findIndex((t: any) => t[match_prop] === value[match_prop])
    );
};

const RemoveItemAtIndex = (input: any[], index: number) => {
    let temp_array: any[] = [...input];
    temp_array.splice(index, 1);
    return temp_array;
};

// Function to calculate the average score from a dictionary
const calculateAverageScore = (scores:any) => {
    let sum = 0;
    let count = 0;
  
    for (const key in scores) {
      if (Object.hasOwnProperty.call(scores, key)) {
        const score = parseFloat(scores[key]);
        if (!isNaN(score)) {
          sum += score;
          count++;
        }
      }
    }
  
    if (count === 0) {
      return 0; // To avoid division by zero
    }
  
    return sum / count;
  };

  // Function to calculate the average score from a dictionary
const calculateAverageScoreInCategory = (scores:any, categoryList:string[]) => {
    let sum = 0;
    let count = 0;
  
    for (const key in scores) {
      if (Object.hasOwnProperty.call(scores, key) && categoryList.includes(key)) {
        const score = parseFloat(scores[key]);
        if (!isNaN(score)) {
          sum += score;
          count++;
        }
      }
    }
  
    if (count === 0) {
      return 0; // To avoid division by zero
    }
  
    return sum / count;
  };

export { RemoveItemFromList, OrderAfterArray, RemoveDuplicates, RemoveItemAtIndex, calculateAverageScore, calculateAverageScoreInCategory };
