export const ABOUT_US = "/about-us";
export const ADMIN = "/admin";
export const AMBASSADORS = "/ambassadors";
export const BLOG = "/news/";
export const COMMENTS_PAGE = "/participated";
export const COMMENTS_PAGE2 = "/deltatt";
export const CONDITONS_OF_SALES = "/general-terms";
export const CONTACT = "/contact";
export const CONTEST = "/ra-contest";
export const CONTEST_SIGNUP = "/contest";
export const COOKIE_POLICY = "/cookie-policy";
export const EDIT_RUN = "/edit-run";
export const FIND_FRIENDS = "/find-friends";
export const FIND_RUN = "/finnlop";
export const CATEGORY = "/category";
export const HOME = "/";
export const INVEST = "/invest";
export const LANDING = "/";
export const MARKETPLACE = "/coaches";
export const MASTERCLASS = "/premium";
export const MASTERCLASS_PORTAL = "/masterclass-portal";
export const MASTERCLASS_PAY = "/masterclass/pay";
export const MISSING_A_RUN = "/add-event";
export const NEW_PASSWORD = "/new-password";
export const NOT_FOUND = "/page-not-found";
export const ORGANIZER_PORTAL = "/organizer-portal";
export const PARTNERS = "/grow-your-event";
export const PASSWORD_FORGET = "/reset-password";
export const PRIVACY_POLICY = "/privacy-policy";
export const PROFILE = "/profile";
export const RACE_ADMIN_PORTAL = "/race-admin-portal";
export const RUNNING_PROGRAM = "/running-program";
export const SIGN_IN = "/login";
export const SIGN_UP2 = "/signup";