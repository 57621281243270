import axios from "axios";

async function check_user_engement(run_guid:string, post_url:string, datetimestamp:number, token:any): Promise<any> {
    const URL =
        process.env.NEXT_PUBLIC_DEVELOPMENT === "development"
            ? "https://cloudrun-x2lc2wibla-ew.a.run.app/check_user_engagement"
            : "https://cloudrun-pgjjiy2k6a-ew.a.run.app/check_user_engagement";


    const headers = {
        Accept: "application/json",
        Authorization: "Bearer " + token,
    };
    const data = {
        run_guid: run_guid,
        post_url: post_url,
        datetimestamp: datetimestamp.toString()
    };

    const promise = axios({
        headers: headers,
        url: URL,
        method: "post",
        timeout: 5000,
        data: data,
    });
    return await promise.then((response: any) => response).catch(() => ({ status: 444 }));
}

async function get_activities(ids:string[], token:any): Promise<any> {
    const URL =
        process.env.NEXT_PUBLIC_DEVELOPMENT === "development"
            ? "https://cloudrun-x2lc2wibla-ew.a.run.app/get_activities"
            : "https://cloudrun-pgjjiy2k6a-ew.a.run.app/get_activities";


    const headers = {
        Accept: "application/json",
        Authorization: "Bearer " + token,
    };
    const data = {
        ids:ids
    };

    const promise = axios({
        headers: headers,
        url: URL,
        method: "post",
        timeout: 5000,
        data: data,
    });
    return await promise.then((response: any) => response).catch(() => ({ status: 444 }));
}

export {check_user_engement, get_activities}